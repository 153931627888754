<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-table :data="list" border>
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column label="图片">
          <template v-slot="s">
            <img :src="s.row.file | tomedia" style="height: 3rem;width: 3rem;border-radius: 3px">
          </template>
        </el-table-column>
        <el-table-column label="链接" prop="link"></el-table-column>
        <el-table-column label="操作" align="center">
          <template #header>
            <el-button @click="editFormBox=true" type="primary">添加</el-button>
          </template>
          <template #default="s">
            <el-button @click="editItem(s.row)" type="primary">编辑</el-button>
            <el-button @click="del(s.row)" type="danger">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="编辑" :visible.sync="editFormBox" @close="editFormClose">
        <el-form label-width="3rem" style="padding: 0 2rem">
          <el-form-item label="图片">
            <y-upload-img v-model="form.file"></y-upload-img>
          </el-form-item>
          <el-form-item label="链接">
            <y-website-choose-link v-model="form.link"></y-website-choose-link>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="editFormBox = false">取 消</el-button>
          <el-button type="primary" @click="edit">确 定</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>

import YUploadImg from "@/components/y-form/y-upload/y_upload_img";
import YWebsiteChooseLink from "@/components/website/yWebsiteChooseLink";

const EditFormTpl = {
  file:"",
  link:"",
}

export default {
  name: "indexBanner",
  components:{
    YWebsiteChooseLink,
    YUploadImg

  },
  data() {
    return {
      list:[],
      form:{...EditFormTpl},
      editFormBox:false,
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    del(item){
      this.$u.api.website.bannerDel({id:item.id}).then(()=>{
        this.$message.success("操作成功");
        this.editFormBox = false;
        this.load();
      })
    },
    editItem(item){
      this.form = {...item};
      this.editFormBox = true;
    },
    editFormClose(){
      this.form = {...EditFormTpl};
      this.editFormBox = false;
    },
    load() {
      this.$u.api.website.bannerAll().then(res=>{
        this.list = res;
      })
    },
    edit(){
      this.$u.api.website.bannerEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.editFormBox = false;
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>